// Libraries
import React from "react"
import { Link, graphql } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"
import { Parallax, Background } from "react-parallax"
import { FaCheck } from "react-icons/fa"
import ScrollAnimation from "react-animate-on-scroll"

// Components (Updated to scoped path)
import Layout from "../components/layout/layout"
import MarkdownContent from "@one-builder/gatsby-theme-ob-master/src/components/content"
// import LatestWork from "@one-builder/gatsby-theme-ob-master/src/components/home/latest-work"
// import FeaturedServices from "@one-builder/gatsby-theme-ob-master/src/components/home/featured-services"
import NewsletterSignup from "@one-builder/gatsby-theme-ob-master/src/components/home/newsletter-signup"
import ServiceDropdown from "@one-builder/gatsby-theme-ob-master/src/components/home/service-dropdown"
import TestimonialSlider from "@one-builder/gatsby-theme-ob-master/src/components/home/testimonial-slider"
import Image from "@one-builder/gatsby-theme-ob-master/src/components/common/image"
import BackgroundImage from "@one-builder/gatsby-theme-ob-master/src/components/common/background-image"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import setSeoData from "@one-builder/gatsby-theme-ob-master/src/helpers/setSeoData"

export default ({ data }) => {
  // Get metadata
  const metadata = useGetTokens()

  // Get Home Page Data
  const { home } = data

  let seo_data = setSeoData(home.frontmatter)

  return (
    <Layout seo_data={seo_data}>
      <section className={`${styles.row} ${styles.row1}`}>
        <div className={styles.container}>
          <div id={styles.containRight}>
            <MarkdownContent
              content={convertTokens(
                home.frontmatter.contentSection1,
                metadata
              )}
            />
            <div className={styles.buttonContain}>
              <Link
                className={styles.btn}
                to={`/request-a-quote/`}
                title="Let's Get to Work!"
              >
                Let's Get to Work!
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id={styles.sub_ctaSection}>
        <div className={styles.container}>
          <div id={styles.subCtaContain} className={styles.flexWrapper}>
            <BackgroundImage
              filename="sub-cta-1.jpg"
              alt="Decking"
              className={styles.ctaItem}
            >
              <Link
                className={styles.btn}
                to={`/services/carpentry/deck-building/`}
                title="Decking"
              >
                <div className={styles.ctaTitle}>Decking</div>
                <p>Get Estimate</p>
              </Link>
            </BackgroundImage>
            <BackgroundImage filename="sub-cta-2.jpg" alt="Bathrooms">
              <Link
                className={styles.btn}
                to={`/services/remodeling/kitchen-bathroom-remodeling/`}
                title="Bathrooms"
              >
                <div className={styles.ctaTitle}>Bathrooms</div>
                <p>Get Estimate</p>
              </Link>
            </BackgroundImage>
            <BackgroundImage filename="sub-cta-3.jpg" alt="Flooring">
              <Link
                className={styles.btn}
                to={`/services/carpentry/hardwood-floor-installation/`}
                title="Flooring"
              >
                <div className={styles.ctaTitle}>Flooring</div>
                <p>Get Estimate</p>
              </Link>
            </BackgroundImage>
          </div>
        </div>
      </section>

      <Parallax
        bgImage={"/images/paint-bg.jpg"}
        strength={-0}
        bgClassName={styles.paintBg}
      >
        <section className={`${styles.row} ${styles.row3}`}>
          <div className={styles.container}>
            <div className={styles.flexWrapper}>
              <div id={styles.containLeft}>
                <div className={styles.container}>
                  <MarkdownContent
                    content={convertTokens(
                      home.frontmatter.contentSection2,
                      metadata
                    )}
                  />
                  <div className={styles.checks}>
                    <div className={styles.inner}>
                      <Link
                        className={styles.btn}
                        to={`/services/carpentry/deck-building/`}
                        title="Decks"
                      >
                        <span className={styles.blueBox}></span>
                        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
                          <span className={styles.checkmark}>
                            <FaCheck />
                          </span>
                        </ScrollAnimation>
                        Decks
                      </Link>
                    </div>
                    {/* <div className={styles.inner}>
                      <Link
                        className={styles.btn}
                        to={`/services/carpentry/drywall-contractor-services/`}
                        title="Drywall"
                      >
                        <span className={styles.blueBox}></span>
                        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
                          <span className={styles.checkmark}>
                            <FaCheck />
                          </span>
                        </ScrollAnimation>
                        Drywall
                      </Link>
                    </div> */}
                    <div className={styles.inner}>
                      <Link
                        className={styles.btn}
                        to={`services/carpentry/hardwood-floor-installation//`}
                        title="Flooring"
                      >
                        <span className={styles.blueBox}></span>
                        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
                          <span className={styles.checkmark}>
                            <FaCheck />
                          </span>
                        </ScrollAnimation>
                        Flooring
                      </Link>
                    </div>
                    <div className={styles.inner}>
                      <Link
                        className={styles.btn}
                        to={`/services/carpentry/windows-doors/`}
                        title="Framing"
                      >
                        <span className={styles.blueBox}></span>
                        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
                          <span className={styles.checkmark}>
                            <FaCheck />
                          </span>
                        </ScrollAnimation>
                        Framing
                      </Link>
                    </div>
                    <div className={styles.inner}>
                      <Link
                        className={styles.btn}
                        to={`/services/remodeling/cabinet-installation/`}
                        title="Cabinetry"
                      >
                        <span className={styles.blueBox}></span>
                        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
                          <span className={styles.checkmark}>
                            <FaCheck />
                          </span>
                        </ScrollAnimation>
                        Cabinetry
                      </Link>
                    </div>
                    <div className={styles.inner}>
                      <Link
                        className={styles.btn}
                        to={`/services/painting/`}
                        title="Painting"
                      >
                        <span className={styles.blueBox}></span>
                        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
                          <span className={styles.checkmark}>
                            <FaCheck />
                          </span>
                        </ScrollAnimation>
                        Painting
                      </Link>
                    </div>
                    <div className={styles.inner}>
                      <Link
                        className={styles.btn}
                        to={`/services/carpentry/windows-doors/`}
                        title="Windows and Doors"
                      >
                        <span className={styles.blueBox}></span>
                        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
                          <span className={styles.checkmark}>
                            <FaCheck />
                          </span>
                        </ScrollAnimation>
                        Windows and Doors
                      </Link>
                    </div>
                    <div className={styles.inner}>
                      <Link
                        className={styles.btn}
                        to={`/services/remodeling/kitchen-bathroom-remodeling/`}
                        title="Windows and Doors"
                      >
                        <span className={styles.blueBox}></span>
                        <ScrollAnimation animateIn="fadeIn" animateOnce="true">
                          <span className={styles.checkmark}>
                            <FaCheck />
                          </span>
                        </ScrollAnimation>
                        Kitchens and Bathrooms
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div id={styles.containRight}>
                <div className={styles.photo1} style={{ width: "100%" }}>
                  <Parallax
                    bgImage={"/images/hp-photo.png"}
                    strength={-150}
                    bgImageAlt="House Remodeling"
                  >
                    <div style={{ height: "560px" }} />
                  </Parallax>
                </div>

                <div className={styles.photo2} style={{ width: "100%" }}>
                  <Parallax
                    bgImage={"/images/hp-photo-2.png"}
                    strength={50}
                    bgImageAlt="Deck Upgrade"
                  >
                    <div style={{ height: "580px" }} />
                  </Parallax>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Parallax>

      <section className={`${styles.row} ${styles.row4}`}>
        <div className={styles.container}>
          <div className={styles.welcomeBox}>
            <div className={styles.inner}>
              <ScrollAnimation
                animateIn="fadeIn"
                delay="600"
                animateOnce="true"
              >
                <div className={styles.imageBox}>
                  <Image filename="van.jpg" alt="Company Van" />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeIn"
                delay="600"
                animateOnce="true"
              >
                <div className={styles.imageBox}>
                  <Image filename="deck.jpg" alt="Finished Deck" />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeIn"
                delay="200"
                animateOnce="true"
              >
                <div className={styles.imageBox}>
                  <Image
                    filename="image1.jpeg"
                    alt="Flooring"
                  />
                </div>
              </ScrollAnimation>
            </div>

            <div className={styles.inner}>
              <div className={styles.welcomeContent}>
                <MarkdownContent
                  content={convertTokens(
                    home.frontmatter.welcomeSection,
                    metadata
                  )}
                />
                <div className={styles.buttonContain}>
                  <Link
                    className={styles.btn}
                    to={`/request-a-quote/`}
                    title="Get Quote"
                  >
                    Get Quote
                  </Link>
                  <a
                    className={styles.btn}
                    href="https://www.facebook.com/HORN-Construction-194555291108/"
                    title="Follow Us on Facebook"
                    target="_blank"
                  >
                    Follow Us on Facebook
                  </a>
                </div>
              </div>
              <div className={styles.photoSub}>
                <div className={styles.firstBox}>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="job-site-hp.jpg"
                        alt="Flooring job in progress"
                      />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="finished-floor.jpg"
                        alt="Finished floor"
                      />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="image0.jpeg"
                        alt="Bathroom"
                      />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="HC_Deck_1.jpeg"
                        alt="Deck"
                      />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="HC_Deck_2.jpeg"
                        alt="Deck"
                      />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="HC_Deck_3.jpeg"
                        alt="Deck"
                      />
                    </div>
                  </ScrollAnimation>
                </div>
                <div className={styles.lastBox}>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image filename="hallway.jpg" alt="Hallway remodel" />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="image2.jpeg"
                        alt="Flooring"
                      />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="HC_Deck_4.jpeg"
                        alt="Deck"
                      />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="HC_Deck_5.jpeg"
                        alt="Deck"
                      />
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    delay="200"
                    animateOnce="true"
                  >
                    <div className={styles.imageBox}>
                      <Image
                        filename="HC_Deck_6.jpeg"
                        alt="Deck"
                      />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <iframe width="560" height="315" style={{maxWidth: 560, margin: '20px auto', width: '100%'}} src="https://www.youtube.com/embed/0UQlcWbZXkY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </section>

      <div className={styles.serviceDropdown}>
        <Parallax
          bgImage={"/images/dropdown-bg.jpg"}
          strength={-0}
          bgClassName={styles.paintBg}
        >
          <section
            id={styles.services}
            className={`${styles.row} ${styles.row5}`}
          >
            <div className={styles.container}>
              <div className={styles.flexWrapper}>
                <div className={styles.containLeft2}>
                  <h2>We do it all.</h2>
                </div>
                <div className={styles.containRight2}>
                  <ServiceDropdown />
                </div>
              </div>
            </div>
          </section>
        </Parallax>
      </div>

      <div className={styles.reviewBG}>
        <Parallax
          bgImage={"/images/review-bg.jpg"}
          strength={0}
          className={styles.featuredServicesParallax}
          bgClassName={styles.reviewBgImg}
        >
          <section
            id={styles.testimonials}
            className={`${styles.row} ${styles.row6}`}
          >
            <div className={styles.container}>
              <div className={styles.flexWrapper}>
                <div id={styles.containLeft}>
                  <h2>What our customers are saying about us.</h2>
                  <div className={styles.buttonContain}>
                    <a
                      className={styles.btn}
                      href="https://www.facebook.com/pg/HORN-Construction-194555291108/reviews/?ref=page_internal"
                      title="Leave a Review"
                      target="_blank"
                    >
                      Share Your Experience
                    </a>
                  </div>
                </div>
                <div id={styles.containRight}>
                  <TestimonialSlider />
                </div>
              </div>
            </div>
          </section>
        </Parallax>
      </div>

      <section id={styles.newsletter}>
        <div className={styles.container}>
          <div id={styles.newsletterContent}>
            <h2>Stay in touch</h2>
            <p>
              Subscribe to receive our latest news and offers from Horn
              Construction.
            </p>
          </div>
          <div id={styles.newsletterForm}>
            <NewsletterSignup />
          </div>
        </div>
      </section>

      <section id={styles.authorized}>
        <div className={styles.container}>
          <h3>Authorized Timbertech Contractor</h3>
          <img src="/images/timbertech.png" alt="Timbertech Contractor" />
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query {
    home: markdownRemark(frontmatter: { title: { eq: "Home" } }) {
      id
      frontmatter {
        title
        is_active
        meta {
          title
          description
          page_visible
        }
        template
        ctaSection
        welcomeSection
        aboutSection
        contentSection1
        contentSection2
        contentSection3
        contentSection4
        contentSection5
        testimonials {
          name
          review
        }
      }
    }
  }
`
